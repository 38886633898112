<template>
    <div>
        <div class="contact-section-wrap py-10 md:py-20">
          <div class="container">
               <div class="contact-section-card">
                    <h1 class="mb-10 text-bold w-full flex-center">Contact</h1>            
                </div>
            <vs-row vs-align="flex-start" vs-type="flex" vs-justify="left">           
              
                <div class="contact-form-section">
                    <vs-row vs-align="flex-start" vs-type="flex" vs-justify="">
                       
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-1/2">
                        <div class="w-full m-2">
                        <vs-input
                            label-placeholder="First Name"
                            v-model="formData.firstName"
                            class="w-full"
                            name="firstName"
                            v-validate="'required|alpha_spaces'"
                            data-vv-as="First Name"/>
                        <span class="text-danger text-sm">{{ errors.first('firstName') }}</span>
                        </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-1/2">
                        <div class="w-full m-2">
                        <vs-input
                            label-placeholder="Last Name"
                            v-model="formData.lastName"
                            class="w-full"
                            name="lastName"
                            v-validate="'required|alpha'"
                            data-vv-as="Last Name"/>
                        <span class="text-danger text-sm">{{ errors.first('lastName') }}</span>
                        </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full">
                        <div class="w-full m-2">
                        <vs-input
                            label-placeholder="Email"
                            v-model="formData.email"
                            class="w-full"
                            name="email"
                            v-validate="'required|email'"
                            data-vv-as="Email"/>
                        <span class="text-danger text-sm">{{ errors.first('email') }}</span>
                        </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full">
                        <div class="w-full m-2">
                        <vs-input
                            label-placeholder="Phone"
                            v-model="formData.phoneNumber"
                            class="w-full"
                            name="phoneNumber"
                            v-validate="'required|numeric'"
                            data-vv-as="Phone Number"/>
                        <span class="text-danger text-sm">{{ errors.first('phoneNumber') }}</span>
                        </div>
                    </vs-col>                   
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full">
                        <div class="w-full m-2 mt-10">
                        <vs-textarea
                            placeholder="Message"
                            v-model="formData.message"
                            class="w-full"
                            name="message"/>
                        </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" class="w-full">
                        <div class="w-full m-2">
                        <vs-button class="w-full" :disabled="!isFormValid" @click="submitHandler">Submit</vs-button>
                        </div>
                    </vs-col>
                    </vs-row>
                </div>
               
            </vs-row>
          </div>
        </div>
    </div>
</template>

  <script>
    import { mapActions } from "vuex";
    import Vue from "vue";
    import VueScrollTo from "vue-scrollto";

    export default {
        data(){
            return {
                formData: {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phoneNumber: '',                    
                    message: '',
                    children: 0,
                    centres: 0,
                },
            };
        },
        computed: {
            isFormValid() {
                return !this.errors.any()
                && this.formData.firstName
                && this.formData.lastName
                && this.formData.email
                && this.formData.phoneNumber                
                && this.formData.message                
            }
        },
        methods: {
            ...mapActions("activity", [                
                "parentContactForm"
                ]),
            showMessage(title, message, color) {
                this.$vs.notify({
                title: title,
                text: message,
                iconPack: 'feather',
                icon: 'icon-alert-circle',
                color: color
                })
            },

            submitHandler() {
                this.$vs.loading();
                this.parentContactForm(this.formData).then(res => {
                if (res.status === 200) {
                    this.showMessage("Success", "‘Your message has been sent to our Admins", "success");
                    this.$vs.loading.close();
                    this.formData = {};
                    this.$validator.reset();
                }
                })
                .catch(err => {
                    if (err.response.status === 403) {
                    this.showMessage("Failed", err.response.data.message, "danger");
                    }
                    this.$vs.loading.close();
                })
            },
        }
    }
</script>
<style lang="scss">
 .contact-form-section {
      @media (min-width:768px) {
        padding: 0 0 0 60px;
      }
    }
</style>